<template>
  <h1 class="text-2xl font-semibold text-gray-900">Platform views</h1>

  <div class="grid grid-cols-4 gap-4 max-w-4xl">
    <UISelectFixed type="DASHBOARD_VIEWS" nullable class="w-full" v-model="filters.item" />
    <UISelectChauffeur v-model="filters.user_id" nullable nullable-label="Alle gebruikers" class="w-full" label="Bekeken door" />
  </div>

  <div class="py-4 max-w-6xl text-xs">
    <UITableResponsive :items="filteredItems" :headers="['Item', 'Bekeken_door', 'Datum_Eerste_keer', 'Datum_Laatste_keer']">
      <template v-slot:colgroup>
        <colgroup>
          <col />
          <col />
          <col />
          <col class="w-full" />
        </colgroup>
      </template>
      <template v-slot:item-Item="{ item }">
        <span class="flex flex-row gap-0.5">
          {{ translateKey(item.item) }}
          <i class="fas fa-eye ml-1" :class="{ 'text-green-500': item.creation_date, 'text-red-500': !item.creation_date }"></i>
        </span>
      </template>
      <template v-slot:item-Bekeken_door="{ item }">
        <span class="font-bold">{{ store.getters.chauffeur(item.user_id)?.label || '?' }}</span>
      </template>
      <template v-slot:item-Datum_Eerste_keer="{ item }">
        <span class="font-bold" v-if="item.creation_date">{{ dateTimeString(item.creation_date) }}</span>
        <span v-else>/</span>
      </template>
      <template v-slot:item-Datum_Laatste_keer="{ item }">
        <span class="font-bold" v-if="item.update_date">{{ dateTimeString(item.update_date) }}</span>
        <span v-else>/</span>
      </template>
    </UITableResponsive>
    <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
  </div>
</template>

<script setup>
import { ref, defineAsyncComponent, computed } from 'vue'
import { useStore } from 'vuex'

import usePaginatedApi from '@/hooks/usePaginatedApi'
import { dateTimeString } from '@/functions/formatDate'
import { DASHBOARD_VIEWS } from '@/constants/OPTIONS'

import UITableResponsive from '@/components/UI/Table/Responsive'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

const store = useStore()

const filters = ref({
  item: null,
  user_id: null,
})

const { limit, offset, count, items } = usePaginatedApi('/api/shared/viewed/list', filters, { watch: true })
limit.value = 50

const DASHBOARD_VIEWS_LIST = DASHBOARD_VIEWS()
const translateKey = (item) => DASHBOARD_VIEWS_LIST.find(option => option.value == item)?.label || item

const filteredItems = computed(() => {
  if (!filters.value.item) return items.value || []

  const list = items.value || []
  const chauffeurs = (store.state.chauffeurs || []).filter(chauffeur => chauffeur.is_active && chauffeur.type !== 'onderaannemer' && !list.find(item => item.user_id == chauffeur.value))

  return [].concat(items.value, chauffeurs.map(({ value }) => ({
    item: filters.value.item,
    user_id: value,
    creation_date: null,
    update_date: null,
  })))
})
</script>
